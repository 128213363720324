@import "@/styles/allVariables";

.swipeable-list {
  flex: 1;
  width: 100%;
  height: 100%;
}

.list-item {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  height: 100%;
  display: flex;
  border-radius: 0.25rem;

  &.bouncing {
    transition: transform 0.5s ease-out;
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0.25rem;

  padding-right: 16px;
  color: white;
  background-color: $danger;
  box-sizing: border-box;
}

.wrapper {
  position: relative;
  transition: max-height 0.5s ease;
  max-height: 1000px;
  transform-origin: top;
  overflow: visible;
  width: 100%;
}