@import "@/styles/allVariables";

.side-nav-container {
  display: none;
  flex-direction: column;
  position: fixed;
  top: $top-nav-height;
  bottom: 0;
  left: 0;
  width: $side-nav-width;
  overflow: auto;
  font-size: 0.9rem;

  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.side-nav-list-item {
  padding: 0.2rem 1rem;
  line-height: 1.8;

  &:hover, &.active {
    background-color: #E9ECEF;
    text-decoration: none;
    color: #000;
  }
}

.side-nav-caret {
  color: #00DBD0;
  height: 1.25em;
}
