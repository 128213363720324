.sign-analytics-form {
  .start-date, .end-date {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
  }

  .start-date > input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .end-date > input {
    border-radius: 0;
  }
}