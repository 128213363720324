@import "@/styles/allVariables";

.sign-online-status-popover {
  table {
    font-size: 0.875em;

    td {
      vertical-align: top;
      padding: 0.5rem 0.25rem;
    }
    tr {
      td:first-child {
        font-weight: bold;
      }
    }
    tr:not(:last-child) {
      border-bottom: 1px solid $light-gray;
    }
  }
}