@import "@/styles/allVariables";

.bottom-nav {
  .nav-link {
    color: $light-gray;
    border: none;
  }
  .nav-link.active {
    color: white;
    background-color: transparent;
  }
  .nav-link>.nav-icon {
    display: inline-block;
    padding: 0.25rem 1rem;
  }
  .nav-link.active>.nav-icon {
    border-radius: 5px;
  }

  .nav-image {
    width: 18px;
    height: 18px;
  }
}