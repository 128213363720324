@import "@/styles/allVariables";

.schedule {
  .playlist-card {
    width: 100%;
    border: 1px solid $light-blue-2;
  }
  // overwrite default styling of no border-radius on first and last list items
  .list-group-item:first-child {
    border-radius: 12px;
  }
  .list-group-item:last-child {
    border-radius: 12px;
  }
  .list-group-item {
    border-radius: 12px;
  }

  .btn-group>.btn-day-of-week:not(:last-child) {
    margin-right: 8px;
  }
  .btn-group>.btn-day-of-week {
    border-radius: 17px !important;
  }
  
  .input-group-prepend-text {
    width: 64px;
    justify-content: flex-end;
  }

  &.schedule-offcanvas {
    background-color: $app-background;
    height: 75%;

    .offcanvas-header {
      background-color: white;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }

    .offcanvas-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// Styles for Repeat Templating
.btn-group>.btn-day-of-week:not(:last-child) {
  margin-right: 8px;
}
.btn-group>.btn-day-of-week {
  border-radius: 17px !important;
}

.input-group-prepend-text {
  width: 64px;
  justify-content: flex-end;
}

.frequency-range-label {
  color: $light-gray;
}

.bg-light-blue-3 {
  background-color: $light-blue-3;
}

.bg-offwhite {
  background-color: $offwhite;
}

.bg-light-blue-1 {
  background-color: $light-blue-1;
}

.bg-light-green-1 {
  background-color: #d6f6d5;
}

.bg-inactive {
  background-color: #F2F2F2;
}

.active-text {
  color: #3B719F;
}

.inactive-text {
  color: #c3ced6;
}

.text-truncate-parent {
  display: flex;
}

.text-truncate-child {
  flex: 1;
  min-width: 0;
}

.text-truncate-string {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.schedule-thumbnail {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 56px;
  height: 56px;
  border-radius: 0.375rem;
  background-color: $dark;

  >img {
    width: 100%;
  }
}

.playlist-accordion-buton {
  &:after {
    width: 24px;
    margin-right: 2px;
  }

  &:not(.collapsed):after {
    width: 20px;
    margin-right: 6px;
  }
}

.grey-modal-body {
  background-color: $app-background;

}

.content-schedule-date-options {
  .start-date, .end-date {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
  }

  .start-date > input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .end-date > input {
    border-radius: 0;
  }
}