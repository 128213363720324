@import "@/styles/allVariables";

.playlist-list-item {
  border-radius: 10px;
  flex: 0 0 15rem;
  overflow: hidden;
  margin: 1rem;
  transition: all 200ms ease;
  cursor: pointer;
  position: relative;
  background-image: linear-gradient(90deg, #227B9F 0%, #0B425B 50%, #06344B 100%);
  background-position: 100%;
  background-size: 200%;
  margin-bottom: 12px;
  border: 0px;
  text-align: center;

  @media (min-width: 576px) {
    width: 160px;
  }

  .playlist-list-item-thumbnail {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit:cover;

    &-missing {
      color: #6c8d9c;
      padding: 30px 0px;
    }
  }

  .img {
    background-position: center;
    background-size: cover;
  }
}

.playlist-actions-menu {
  .menu-item {
    display: flex;
  }

  .menu-item-icon-container {
    width: 20px;
    text-align: center;
  }

  .menu-item-icon {
    color: $title-blue;
    font-size: 1.375rem;
  }

  .menu-item-text {
    font-weight: 500;
    margin-left: 15px;
  }
}
