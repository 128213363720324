@import "@/styles/allVariables";

.sign-horizontal-list {
  flex-wrap: wrap;

  > * {
    margin-bottom: 4px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  >.btn {
    flex: 0 1 auto;
  }
}

.light-blue-button {
  color: $light-blue-2;
  border-color: $light-blue-2;
  border-radius: 0 
}

.bg-offwhite {
  background-color: $offwhite;
}
