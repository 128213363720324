.file-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  img, video {
    border-radius: 0;
    width: 100%;
    height: 100%;
  }

  video {
    // must be block to avoid randon margin-bottom that some browsers apply to video tags
    display: block;
  }
}

.modal-media-preview {
  .file-container {
    max-height: none !important;
    max-width: none !important;
  }
}