@import "@/styles/allVariables";

.file-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.file-list-item {
  padding: 12px;
  flex: 0 0 15rem;
  transition: all 200ms ease;
  cursor: pointer;
  position: relative;
  max-width: 165px;

  @media (min-width: 576px) {
    width: 160px;
  }

  .file-list-item-thumbnail {
    position: absolute;
    top:0 ;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .img {
    background-position: top;
    background-size: cover;
  }
}

.file-list-item-footer {
  min-height: 40px;
}

.multiselect-icon {
  position: absolute;
  left: -8px;
  bottom: 4px;
  font-size: 18px;
}

.folder-card {
  height: 124px;
  margin-bottom: 12px;
  border: transparent;
  text-align: center;

  @media (min-width: 576px) {
    width: 160px;
  }
}

.add-file-folder-button {
  height: 100%;
  width: 120px;
  border-style: dashed;

  >.svg-inline--fa {
    width: 45px;
    height: 45px;
  }
}

.caret-hide {
  border: 0px;
  background-color: white;
}

.caret-hide.dropdown-toggle::after {
  display: none;
}

.search-container {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
}

.add-folder-button {
  position: absolute;
  bottom: 60px;
  right: 10px;
  border-radius: 25px;
  width: 50px;
  height: 50px;
}

.file-details-container {
  max-width: 400px;
}

.media-container {
  video, img {
    width: 100%;
  }
}

.upload-input {
  opacity: 0;
  width: 0px;
  height: 0px;
}

.drop-file {
  border: 5px blue solid;
  width: 150px;
  height: 75px;
}

.cursor-pointer {
  cursor: pointer;
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  transform: scale(0.7);
  background-color: rgba(255, 255, 255, 1);
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  filter: alpha(opacity=20);
}